let inited = false;
const updateScrollVariables = () => {
  document.documentElement.style.setProperty('--scroll-x', window.scrollX + "px");
  document.documentElement.style.setProperty('--scroll-y', window.scrollY + "px");
  const noscroll = document.body.classList.contains('noscroll');
  if (window.scrollY == 0) { 
    if (!noscroll) {
       document.body.classList.add('noscroll');
    } 
  } 
  else { 
    if (noscroll) {
      document.body.classList.remove('noscroll');
    }
  }
  if (!inited) {
    inited = true
    document.body.classList.add('scrollinited');
  }
};

// Update scroll variables on page load and scroll
window.addEventListener("load", updateScrollVariables);
window.addEventListener("scroll", updateScrollVariables);

